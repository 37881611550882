/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/intersection-observer@0.5.0/intersection-observer.min.js
 * - /npm/slideout@1.0.1/dist/slideout.min.js
 * - /npm/scrollama@1.4.3/build/scrollama.min.js
 * - /npm/blockui@1.0.0/jquery.blockUI.min.js
 * - /npm/basicscroll@2.1.1/dist/basicScroll.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
